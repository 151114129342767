.notification-preview {
  background-color:  govuk-colour('blue');
  color: govuk-colour('white');
  padding: 30px;
  word-wrap: break-word;

  p {
    color: govuk-colour('white');
  }

  h1 {
    @include govuk-typography-common;
    @include govuk-font(24, 'bold');
  }

  h1 + h1 {
    margin-top: 16px;
  }

  li {
    list-style: disc;
    margin-left: 1em;
    padding-left: 1em;
  }

  a {
    color: #fff;
  }

  blockquote {
    padding-left: 1em;
    border-left: 10px solid $govuk-border-colour;
  }

  strong {
    font-weight: bold;
  }
}


.govuk-box-highlight {
  &--accessible {
    background: #208070;
  }
}
