/**
  Styles for the password hints during the update password flow

  Items that are correct in the password make up are marked
  as green, whereas those that are not satisfied are red.

  In addition the information is displayed as the input hint text
  but when there is an error this must take the colours as well which
  is why there has to be a very specific selector.
*/

.password-validation,
.govuk-form-group--error .password-validation .govuk-hint {
  color: govuk-colour("green");
  list-style: none;
  list-style-position: outside;
}

.password-validation-failure,
.govuk-form-group--error .password-validation-failure > .govuk-hint {
  color: $govuk-error-colour;
  list-style: none;
  list-style-position: outside;
}
