.table__header--tall {
  @extend .govuk-table__header;

    padding: 15px;
    padding-left: 0;
}

.table__cell--tall {
  @extend .govuk-table__cell;

    padding: 15px;
    padding-left: 0;
}

.table__cell--totals {
  @extend .govuk-table__cell;
  
    border-bottom: 0
}

.table__header--totals {
  border-top: 2px solid govuk-colour("black");  
  border-bottom: none;
}

.table__header--width-10 {
  @extend .govuk-table__header;
  
    width: 10%;
}

.table__header--width-15 {
  @extend .govuk-table__header;
  width: 15%;
}

.table__header--width-20 {
  @extend .govuk-table__header;
  
    width: 20%;
}

.table__header--center { 
  text-align: center;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  @include govuk-typography-weight-regular;
  max-width: 250px;
}


.table__row--clickable {
  @extend .govuk-table__row !optional;

  // If JS is enabled, user can click entire table row to view licence
  // Hint to user this is possible by displaying hover state on table row
  .js-enabled & {
    &:hover {
      cursor: pointer;
      background: govuk-colour("light-grey");
    }
  }
}

.thick_black_underline{
  border-bottom: 3px solid black;
}
