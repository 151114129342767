// ========== ========== ========== ========== ==========
// styles for new GDS navigation style
// ========== ========== ========== ========== ==========
// this probably needs more love for smaller screens

// subnav container
.navbar {
  border-bottom: 1px solid $govuk-border-colour;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 19px;
  }
}

.navbar__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

// subnav list
.navbar__item {
  font-weight: 700;
  margin-bottom: 0;
  display: inline-block;
}

// active link for subnav
.navbar__link {
  margin-right: 15px;
  color: $govuk-text-colour !important;
  display: inline-block;
  text-decoration: none;
  box-shadow: inset 0px -5px 0px 0px rgba(0,0,0,0);
  padding: 15px 5px 10px 5px;
}

.navbar__link--active {
  color: $govuk-link-colour !important;
  box-shadow: inset 0px -5px 0px 0px $govuk-link-colour;
  padding: 15px 5px 10px 5px;
}
