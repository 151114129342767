.company-switcher {
  @include govuk-font($size: 16);
  border-bottom: 1px solid $govuk-border-colour;

  @media (min-width: 768px) {
    font-size: 19px;
  }

  padding: 15px 0;
}


.company-switcher__link {
  margin-left: 1rem;
}
