// Default
#cookie-banner-js-enabled {
  display: none;
}

// JS enabled browsers
.js-enabled {

  #cookie-banner-js-disabled {
    display: none;
  }

  #cookie-banner-js-enabled {
    display: block;
  }

}
