.alert {
  @extend .govuk-inset-text;

  border-color: #144e81;
  background-color: #d2e2f1;
  margin-top: 0;

  a {
    color: #144e81;
  }
}

.alert--error {
  @extend .alert;

  border-color: #942514;
  background-color: #f6d7d2;

  a {
    color: #942514;
  }
}
