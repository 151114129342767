.data-row {

  @media screen and (min-width: 641px) {
    display: flex;
  }

  margin-left: -15px;
  margin-right: -15px;
}
.data-row__item {
  padding: 0 15px;
  box-sizing: border-box;
}
