/**
  Extends the section break style from the govuk design system
  to add a thicker line

  https://design-system.service.gov.uk/styles/typography/#section-break
*/
.section-break--heavy {
  @extend .govuk-section-break--visible;
  border-top: none;
  border-bottom: 2px solid $govuk-text-colour;
}

.section-break--margin-7 {
  @extend .govuk-section-break--visible;
  margin-bottom: 40px;
}
