.meta {
  @extend .govuk-summary-list;
}
.meta__row {
  @extend .govuk-summary-list__row;
}
.meta__key {
  @extend .govuk-summary-list__key;

  @include govuk-media-query($from: tablet) {

    font-weight: 400;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

}

.meta__value {
  @extend .govuk-summary-list__value;

  @include govuk-media-query($from: tablet) {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}
