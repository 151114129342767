.message-preview {
  background-color: govuk-colour("light-grey");
  border: 5px solid  $govuk-secondary-text-colour;
  padding: 2rem;
}

.message-preview h1 {
  font-size: 1.1875rem;
  margin: 0;
}
